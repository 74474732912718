<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4">
        <h2>{{ $t("analytics.trending_items.title") }}</h2>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ $t("analytics.trending_items.subtitle") }}
        </span>
      </div>
      <div class="col-xl-8 ">
        <UsStatsConfiguration></UsStatsConfiguration>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_items.list.table.title") }}

                <span class="d-block text-muted pt-2 font-size-sm">{{
                  $t("analytics.trending_items.list.table.subtitle")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="trending_items.table"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :loading-text="
                      $t('analytics.trending_items.list.table.loading')
                    "
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.name="{ item }">
                      <router-link
                        :to="{
                          name: 'trending-item',
                          query: { id: item.id }
                        }"
                      >
                        {{ item.name }}
                      </router-link>
                    </template>
                    <template v-slot:item.result_setting_type_id="{ item }">
                      {{
                        item.result_setting_type_id === "1"
                          ? "Autocomplete"
                          : "Search"
                      }}
                    </template>
                    <template v-slot:item.queries="{ item }">
                      {{ parseInt(item.queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.unique_queries="{ item }">
                      {{ parseInt(item.unique_queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.avg_position="{ item }">
                      {{
                        parseFloat(item.avg_position).toLocaleString("cs-CZ", {
                          maximumFractionDigits: 2
                        })
                      }}
                    </template>
                    <template v-slot:top>
                      <div class="row">
                        <div
                          class="d-flex align-items-center position-relative my-1"
                        >
                          <span
                            class="svg-icon svg-icon-1 position-absolute ms-6"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x="17.0365"
                                y="15.1223"
                                width="8.15546"
                                height="2"
                                rx="1"
                                transform="rotate(45 17.0365 15.1223)"
                                fill="black"
                              ></rect>
                              <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                          <input
                            type="text"
                            v-model="search"
                            :placeholder="
                              $t(
                                'analytics.trending_items.list.table.search_field'
                              )
                            "
                            class="form-control form-control-solid w-250px ps-14"
                          />
                        </div>
                        <div
                          class="d-flex align-items-center position-relative my-1 ml-2"
                        >
                          <v-chip-group
                            multiple
                            v-model="activeFilters.collection_name"
                            class="py-2"
                          >
                            <template v-for="item in filters.collection_name">
                              <v-chip
                                :key="`${item}`"
                                :value="item"
                                :filter="
                                  activeFilters.collection_name.includes(item)
                                "
                                :ripple="false"
                              >
                                {{ item }}
                              </v-chip>
                            </template>
                          </v-chip-group>
                        </div>
                      </div>
                    </template>

                    <template v-slot:no-data>
                      {{ $t("common.nodata") }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { LOAD_TRENDING_ITEMS } from "@/core/services/store/search_history.module";
import UsStatsConfiguration from "@/view/content/widgets/USStatsConfiguration.vue";
export default {
  components: { UsStatsConfiguration },
  data() {
    return {
      options: {},
      search: "",
      filter: "search",
      filters: { collection_name: [] },
      activeFilters: [{ collection_name: [] }],
      headers: [
        { text: "#", value: "row" },
        {
          text: this.$t(
            "analytics.trending_items.list.table.headers.result_type"
          ),
          value: "result_setting_type_id"
        },
        {
          text: this.$t(
            "analytics.trending_items.list.table.headers.item_name"
          ),
          value: "name"
        },
        {
          text: this.$t(
            "analytics.trending_items.list.table.headers.collection_name"
          ),
          value: "collection_name",
          filter: value => {
            return this.activeFilters.collection_name
              ? this.activeFilters.collection_name.includes(value)
              : true;
          }
        },
        {
          text: this.$t(
            "analytics.trending_items.list.table.headers.position_avg"
          ),
          value: "avg_position"
        },
        {
          text: this.$t("analytics.trending_items.list.table.headers.queries"),
          value: "queries"
        },
        {
          text: this.$t(
            "analytics.trending_items.list.table.headers.unique_queries"
          ),
          value: "unique_queries"
        }
      ]
    };
  },
  watch: {
    currentUserConfiguration: {
      handler: function() {
        this.reloadData();
      },
      deep: true
    },
    changeProject() {
      this.reloadData();
    },
    trending_items() {
      this.initFilters();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    userConf() {
      return this.currentUserConfiguration.stats;
    },
    ...mapState({
      trending_items: state => state.searchHistory.tables.trending_items,
      loading: state => state.searchHistory.loading
    }),
    ...mapGetters(["currentProject", "currentUserConfiguration"])
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD. MM YYYY");
    }
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    initFilters() {
      for (let col in this.filters) {
        this.filters[col] = this.trending_items.table
          .map(d => {
            return d[col];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }

      this.activeFilters = Object.assign({}, this.filters);
    },

    collectionFilter() {},
    reloadData() {
      const data = [];
      if (
        this.userConf.result_object === "search" ||
        this.userConf.result_object === "all"
      ) {
        data.push("result_setting_type_id[]=2");
      }
      if (
        this.userConf.result_object === "autocomplete" ||
        this.userConf.result_object === "all"
      ) {
        data.push("result_setting_type_id[]=1");
      }

      if (this.userConf.date_range.startDate) {
        data.push(
          `start=${moment(this.userConf.date_range.startDate).format(
            "yyyy-MM-DD"
          )}`
        );
      }
      if (this.userConf.date_range.endDate) {
        data.push(
          `end=${moment(this.userConf.date_range.endDate).format("yyyy-MM-DD")}`
        );
      }
      if (this.userConf.language) {
        data.push(`language=${this.userConf.language}`);
      }

      this.$store.dispatch(LOAD_TRENDING_ITEMS, data.join("&"));
    }
  }
};
</script>
